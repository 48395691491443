import _ from 'underscore'
import Vue from 'vue'
import Dialog from '@/components/dialog'
import Form from '@/components/form'
import ApiService from '@/util/api.service'
import ProfileGroups from '@/components/ProfileGroups.vue'

const groups = async ({
  profile
}) => {
  const Elem = Vue.extend(ProfileGroups)
  const _dialog = new Dialog({
    title: `Grupos ( ${profile.name} )`,
    height: '800',
  })
  _dialog.open()
  _dialog.appendPage(Elem, { profile })
}

const geofences = async ({
  profile
}) => {
  window.VMA.loading(true)
  try {
    const _geofences = await ApiService({
      url: '/geofences',
      method: 'get',
      params: {
        all: true
      }
    })

    const permissions = await ApiService({
      url: 'v2/profiles/geofences/' + profile.id,
      method: 'get',
    });

    const form = new Form({
      schema: _geofences.reduce((acc, geofence) => ({
        ...acc,
        [geofence.id]: {
          label: geofence.name,
          type: Boolean,
          changeOnLoad: false,
          onChange: async (val) => {
            if (val) {
              ApiService({
                url: 'v2/profiles/geofences',
                method: 'post',
                data: { profileId: profile.id, geofenceId: geofence.id }
              }).then(() => {
              }).catch((err) => {
                console.error(err)
              })
            } else {
              ApiService({
                url: 'v2/profiles/geofences',
                method: 'delete',
                data: { profileId: profile.id, geofenceId: geofence.id }
              }).then(() => {
              }).catch((err) => {
                console.error(err)
              })
            }
          }
        }
      }), {}),
      item: permissions.reduce((acc, permission) => ({
        ...acc,
        [permission.geofenceId]: true
      }), {}),
    })
    const dialog = new Dialog({
      title: 'Geocercas',
      subtitle: profile.name
    })
    dialog.open()
    dialog.append(form)
  } catch (err) {
    // ok
    console.error({ err })
    window.VMA.showError({ title: 'Error al cargar las geocercas' })
  } finally {
    window.VMA.loading(false)
  }
}

export default {
  groups,
  geofences
}
